import React from 'react';

export default props => (
  <button
    aria-label="close panel button"
    style={{
      margin: '16px',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 100,
      cursor: 'pointer',
    }}
    className="reset-button"
    onClick={props.onClick}
  >
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.35355"
        y1="0.646447"
        x2="25.3536"
        y2="24.6464"
        stroke={props.color || 'black'}
      />
      <line
        y1="-0.5"
        x2="33.9411"
        y2="-0.5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 25 1)"
        stroke={props.color || 'black'}
      />
    </svg>
  </button>
);
