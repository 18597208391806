import React from 'react';
// @ts-ignore
import floatingSkullImage from './FLOATING-SKULL-1.png';
import './style.css';
import verses from './verses';

interface Props {
  isInteractive?: boolean;
}

const TextBox = (props: { text: { verse: string; chapter: string } }) => (
  <div
    style={{
      width: '245px',
      background: '#FFFEEC',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      boxShadow: '5px 4px 8px rgba(0, 0, 0, 0.6)',
      padding: '15px 14px 10px 14px',
    }}
  >
    <div
      style={{
        fontFamily: 'Akkurat-Mono',
        fontSize: '10px',
        lineHeight: '10px',
        textAlign: 'center',
        margin: 0,
      }}
    >
      {props.text.verse}
      <br />
      <br />
      <b>{props.text.chapter}</b>
    </div>
  </div>
);

export class FloatingSkull extends React.PureComponent<Props> {
  componentDidMount() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });
  }

  static defaultProps: Props = {
    isInteractive: true,
  };

  state = {
    showText: false,
    bibleVerses: verses,
    bibleVerseIndex: 0,
    height: 800,
    width: 1280,
  };

  render() {
    const tablet = this.state.width < 769;
    if (tablet) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            className="floating"
            src={floatingSkullImage}
            width="180"
            alt="floating skull"
          />
        </div>
      );
    }
    const text = this.state.bibleVerses[this.state.bibleVerseIndex];
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {this.state.showText && <TextBox text={text}></TextBox>}
        <button
          aria-label="floating skull oracle"
          className="reset-button"
          onMouseEnter={
            this.props.isInteractive
              ? () => this.setState({ showText: true })
              : () => {}
          }
          onMouseLeave={
            this.props.isInteractive
              ? () =>
                  this.setState({
                    showText: false,
                    bibleVerseIndex:
                      (this.state.bibleVerseIndex + 1) %
                      this.state.bibleVerses.length,
                  })
              : () => {}
          }
        >
          <img
            className="floating"
            src={floatingSkullImage}
            alt="floating skull"
          />
        </button>
      </div>
    );
  }
}

export default FloatingSkull;
