import React from 'react';
import './styles.scss';
import FloatingSkull from '../floating-skull';
import Img from 'gatsby-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { debounce, throttle } from 'lodash';

interface Props {
  imageAreaProps: any[];
  menuHeight: number;
}

export default class ImageArea extends React.Component<Props> {
  onScrollThrottled: any;
  constructor(props: Props) {
    super(props);
    this.onScrollThrottled = debounce(this.onScroll, 100, {
      leading: true,
      trailing: false,
    });
  }

  state = {
    height: 800,
    width: 1280,
    imgChosen: null,
    images: this.props.imageAreaProps,
  };

  /**
   * This function gets the index of a random image close to the users screen.
   */
  getRandomImageIndexOnScreen = () => {
    function outGifs(img: any): boolean {
      return !(!img.fluid.src.includes || img.fluid.src.includes('gif'));
    }

    const images = [
      ...this.state.images
        .map((i, index) => {
          return {
            ...i,
            index,
          };
        })
        .filter(outGifs)
        .sort((a, b) => {
          const imageAreaEl = document.getElementById('image-area')!;
          const currentScrollArea = imageAreaEl.scrollTop;
          const imageADiff = Math.abs(a.y - currentScrollArea);
          const imageBDiff = Math.abs(b.y - currentScrollArea);
          return imageADiff - imageBDiff;
        }),
    ];
    const index = images[Math.floor(Math.random() * 4)].index;
    return index;
  };

  onScroll = () => {
    this.setState({
      imgChosen: this.getRandomImageIndexOnScreen(),
    });
  };

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    document
      .querySelector('.image-area')
      ?.addEventListener('scroll', this.onScroll);

    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });
  }

  render() {
    const props = this.props;
    return (
      <div id="image-area" onScroll={this.onScrollThrottled}>
        {this.state.images.map((img, i) => {
          /**
           * Calculates the relative position based on a 1280 screen size.
           */
          const calculateRelativePositions = (img: any) => {
            const baseViewportWidth = 1280;
            const baseViewPortHeight = 947;
            const wRatio = this.state.width / baseViewportWidth;

            return {
              ...img,
              x: img.x * wRatio,
              y: img.y * wRatio,
              height: img.height * wRatio,
              width: img.width * wRatio,
              fluid: {
                ...img.fluid,
                presentationHeight: img.fluid.presentationHeight * wRatio,
                presentationWidth: img.fluid.presentationWidth * wRatio,
              },
            };
          };

          img = calculateRelativePositions(img);
          return (
            <button
              aria-label="project image"
              key={i}
              className="reset-button c-glitch"
              style={{
                position: 'absolute',
                left: img.x,
                top: img.y,
                opacity: img.show ? '100%' : '0%',
                cursor: img.show ? 'help' : 'unset',
                height: img.height || img.fluid.presentationHeight,
                width: img.width || img.fluid.presentationWidth || 'auto',
              }}
              draggable={false}
              disabled={!img.show}
              onMouseEnter={img.show ? img.onMouseEnter : () => {}}
              onClick={img.show ? img.onMouseEnter : () => {}}
            >
              {!img.fluid.src.includes || img.fluid.src.includes('gif') ? (
                <LazyLoadImage
                  effect="black-and-white"
                  alt={img.alt}
                  src={img.fluid.src}
                  height={img.height}
                  width={img.width}
                />
              ) : (
                <>
                  <Img
                    loading="eager"
                    alt={img.alt}
                    fluid={img.fluid}
                    fadeIn={true}
                    className={
                      i === this.state.imgChosen ? 'c-glitch__img' : ''
                    }
                    imgStyle={{
                      objectFit: 'fill',
                      height: img.height || img.fluid.presentationHeight,
                      width: img.width || img.fluid.presentationWidth,
                    }}
                    onLoad={() => {
                      if (window !== undefined) {
                        document
                          .getElementById(`image-area-image-${i}`)
                          ?.remove();
                      }
                    }}
                  />
                  <Img
                    loading="eager"
                    alt={img.alt}
                    fluid={img.fluid}
                    className={
                      i === this.state.imgChosen
                        ? 'c-glitch__img'
                        : 'invisible-img'
                    }
                    fadeIn={true}
                    imgStyle={{
                      objectFit: 'fill',
                      height: img.height || img.fluid.presentationHeight,
                      width: img.width || img.fluid.presentationWidth,
                    }}
                    onLoad={() => {
                      if (window !== undefined) {
                        document
                          .getElementById(`image-area-image-${i}`)
                          ?.remove();
                      }
                    }}
                  />
                  <Img
                    loading="eager"
                    alt={img.alt}
                    fluid={img.fluid}
                    fadeIn={true}
                    className={
                      i === this.state.imgChosen
                        ? 'c-glitch__img'
                        : 'invisible-img'
                    }
                    imgStyle={{
                      objectFit: 'fill',
                      height: img.height || img.fluid.presentationHeight,
                      width: img.width || img.fluid.presentationWidth,
                    }}
                    onLoad={() => {
                      if (window !== undefined) {
                        document
                          .getElementById(`image-area-image-${i}`)
                          ?.remove();
                      }
                    }}
                  />
                  <Img
                    loading="eager"
                    alt={img.alt}
                    fluid={img.fluid}
                    fadeIn={true}
                    className={
                      i === this.state.imgChosen
                        ? 'c-glitch__img'
                        : 'invisible-img'
                    }
                    imgStyle={{
                      objectFit: 'fill',
                      height: img.height || img.fluid.presentationHeight,
                      width: img.width || img.fluid.presentationWidth,
                    }}
                    onLoad={() => {
                      if (window !== undefined) {
                        document
                          .getElementById(`image-area-image-${i}`)
                          ?.remove();
                      }
                    }}
                  />
                  <Img
                    loading="eager"
                    alt={img.alt}
                    fluid={img.fluid}
                    className={
                      i === this.state.imgChosen
                        ? 'c-glitch__img'
                        : 'invisible-img'
                    }
                    fadeIn={true}
                    imgStyle={{
                      objectFit: 'fill',
                      height: img.height || img.fluid.presentationHeight,
                      width: img.width || img.fluid.presentationWidth,
                    }}
                    onLoad={() => {
                      if (window !== undefined) {
                        document
                          .getElementById(`image-area-image-${i}`)
                          ?.remove();
                      }
                    }}
                  />
                </>
              )}
            </button>
          );
        })}
        <div
          id="floating-skull"
          style={{
            position: 'fixed',
            bottom: `${props.menuHeight + 15}px`,
            ...(this.state.width > 1023
              ? { right: 0 }
              : { left: 0, transform: 'scaleX(-1)' }),
          }}
        >
          <FloatingSkull isInteractive={true} />
        </div>
      </div>
    );
  }
}
