import React from 'react';
import logo1 from './images/logo1.png';
import logo2 from './images/logo2.png';
import logo3 from './images/logo3.png';
import logo4 from './images/logo4.png';
import logo5 from './images/logo5.png';

export default class Logo extends React.Component {
  state = {
    images: [logo1, logo2, logo3, logo4, logo5],
    curr: 0,
  };
  render() {
    const n = this.state.images.length;
    const i = this.state.curr;
    return (
      <button
        aria-label="logo options"
        style={{
          position: 'fixed',
          top: 25,
          left: 23,
          zIndex: 50,
        }}
        className="reset-button"
        onClick={() => this.setState({ curr: i + 1 })}
      >
        <img src={this.state.images[((i % n) + n) % n]} alt="Sanitarium Logo" />
      </button>
    );
  }
}
