import React from 'react';
import './styles.css';
import sanitariumLogoMain from './sanitarium-logo-main';
import TypingElement from '../typing-element';
import code from './code';

const topText = [
  `<span style="text-decoration:line-through;">`,
  ...`
Welcome to where time stands still No one leaves and no one will Moon is full, never seems to change Just labeled mentally deranged Dream the same thing every </br></br>night I see our freedom in my sight No locked doors, no windows barred No things to make my brain seem scarred
Sleep my friend and you will see That dream is my reality They keep me locked up in this cage
Can’t they see it’s why my brain says “rage” Sanitarium, leave me be Sanitarium, just leave me alone Build my fear of what’s out there Cannot breathe the open </br></br>air Whisper things into my brain
Assuring me that I’m insane They think our heads are in their handsBut violent use brings violent plans Keep him tied, it makes him well He’s getting better, </br></br>can’t you tell? No more can they keep us in Listen, damn it, we will win They see it right, they see it well But they think this saves us from our hell </br></br>Sanitarium, leave me be</br>
Sanitarium, just leave me alone Sanitarium, just leave me alone</br></br>
</br></br>
For reaching out again</br>
</br>
`.split(' '),
  `</span>`,
];

const sanitariumAsciiLogo = `
</br>
                      .__  __               .__               </br>
  ___________    ____ |__|/  |______ _______|__|__ __  _____  </br>
 /  ___/\\__  \\  /    \\|  \\   __\\__  \\\\_  __ \\  |  |  \\/     \\ </br>
 \\___ \\  / __ \\|   |  \\  ||  |  / __ \\|  | \\/  |  |  /  Y Y  \\</br>
/____  >(____  /___|  /__||__| (____  /__|  |__|____/|__|_|  /</br>
     \\/      \\/     \\/              \\/                     \\/ </br>
</br>
`.replace(/ /g, '\u00a0');

export default class LoadingScreen extends React.Component {
  state = {
    content: '',
    finishedLoading: false,
    canExit: false,
    countDown: 13,
    isBooting: true,
  };
  loadingID!: NodeJS.Timeout;
  textWritingID!: NodeJS.Timeout;
  canExitID!: NodeJS.Timeout;
  decrementCountID!: NodeJS.Timeout;
  charPos = 0;

  componentDidMount() {
    this.loadingID = setInterval(() => {
      document.getElementById('loading-container')?.scrollIntoView(false);
    }, 40);
  }

  render() {
    if (process.env.NODE_ENV === 'development' || this.state.finishedLoading) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          color: 'white',
          fontFamily: 'Akkurat-Mono',
          overflowY: 'scroll',
          overscrollBehaviorY: 'contain',
          scrollSnapType: 'y mandatory',
          WebkitOverflowScrolling: 'touch',
          zIndex: 100000,
        }}
        className="hide-scrollbar stay-bottom"
        onClick={() => {
          if (!this.state.canExit) return;
          this.setState({ finishedLoading: true });
        }}
      >
        <div id="loading-container" style={{ overflowY: 'scroll' }}>
          <p
            style={{
              fontSize: '5px',
              lineHeight: '6px',
              zIndex: 100001,
              backgroundColor: 'black',
              textAlign: 'right',
              margin: 0,
              color: 'white',
              fontFamily: 'Akkurat-Mono',
            }}
            dangerouslySetInnerHTML={{ __html: sanitariumAsciiLogo }}
          ></p>
          {this.state.isBooting ? (
            <div>
              <TypingElement
                style={{
                  padding: '20px',
                }}
                text={['Booting', '_']}
                typingTime={40}
              />
              <TypingElement
                style={{
                  padding: '20px',
                }}
                text={code}
                typingTime={16}
                delay={2 * 1000}
                onFinish={() => {
                  this.setState({ isBooting: false });
                }}
              />
            </div>
          ) : (
            <React.Fragment>
              <TypingElement
                style={{
                  padding: '20px',
                  fontSize: '10px',
                }}
                text={topText}
                typingTime={5}
                delay={1.5 * 1000}
              />
              <TypingElement
                style={{
                  padding: '20px',
                }}
                text={sanitariumLogoMain}
                typingTime={200}
                delay={topText.length * 5 + 1.5 * 1000}
                onFinish={() => {
                  this.setState({
                    canExit: true,
                  });

                  this.decrementCountID = setInterval(() => {
                    const countDown = this.state.countDown - 1;
                    if (countDown < 0) {
                      clearInterval(this.decrementCountID);
                      return;
                    }
                    this.setState({
                      countDown,
                    });
                  }, 1000);

                  this.canExitID = setTimeout(() => {
                    this.setState({
                      finishedLoading: true,
                    });
                  }, 13 * 1000);
                }}
              />
              {this.state.canExit && (
                <p
                  style={{
                    padding: '20px',
                  }}
                >
                  PRESS OR CLICK ANYTHING TO ENTER OR WAIT (
                  {this.state.countDown} secs)
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
