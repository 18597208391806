interface Verse {
  verse: string;
  chapter: string;
}

export default [
  {
    verse:
      'A froward man soweth strife: and a whisperer separateth chief friends.',
    chapter: 'Proverbs 16:28',
  },
  {
    verse:
      'And when they had prayed, the place was shaken where they were assembled together; and they were all filled with the Holy Ghost, and they spake the word of God with boldness.',
    chapter: 'Acts 4:31',
  },
  {
    verse:
      'This then is the message which we have heard of him, and declare unto you, that God is light, and in him is no darkness at all.',
    chapter: '1 John 1:5',
  },
  {
    verse: 'Let your moderation be known unto all men. The Lord is at hand.',
    chapter: 'Philippians 4:5',
  },
  {
    verse:
      'Ye lust, and have not: ye kill, and desire to have, and cannot obtain: ye fight and war, yet ye have not, because ye ask not.',
    chapter: 'James 4:2',
  },
  {
    verse:
      'In all labour there is profit: but the talk of the lips tendeth only to penury.',
    chapter: 'Proverbs 14:23',
  },
  {
    verse:
      'Put away from thee a froward mouth, and perverse lips put far from thee.',
    chapter: 'Proverbs 4:24',
  },
  {
    verse:
      'And for this cause he is the mediator of the new testament, that by means of death, for the redemption of the transgressions that were under the first testament, they which are called might receive the promise of eternal inheritance.',
    chapter: 'Hebrews 9:15',
  },
  {
    verse:
      'For God sent not his Son into the world to condemn the world; but that the world through him might be saved.',
    chapter: 'John 3:17',
  },
  {
    verse:
      'Let no man therefore judge you in meat, or in drink, or in respect of an holyday, or of the new moon, or of the sabbath days: Which are a shadow of things to come; but the body is of Christ.',
    chapter: 'Colossians 2:16-17',
  },
] as Verse[];
