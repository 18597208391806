import React from 'react';
import './styles.css';
import MobileBottomMenu from './mobile';
import BottomMenu from './desktop';

export interface MenuProps {
  menuDescription: string;
  filterButtons: any;
  about: string;
  gameInstructions: string;
}

export default class BottomMenuContainer extends React.Component<MenuProps> {
  state = { height: 800, width: 1280 };

  componentDidMount() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    window.addEventListener('resize', () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });
  }

  render() {
    const props = this.props;

    const tablet = this.state.width < 769;
    if (tablet) {
      return <MobileBottomMenu {...props} />;
    } else {
      return <BottomMenu {...props} />;
    }
  }
}
