import React from 'react';
import { delay } from 'lodash';

interface TypingProps {
  typingTime: number;
  text: any[];
  style: React.CSSProperties;
  onFinish?: Function;
  delay?: number;
}

export default class TypingElement extends React.Component<TypingProps> {
  state = {
    content: '',
  };
  textWritingID!: NodeJS.Timeout;
  charPos = 0;
  currTime = 0;

  componentDidMount() {
    const callback = this.props.onFinish;
    this.textWritingID = setInterval(() => {
      if (this.props.delay && this.currTime < this.props.delay) {
        this.currTime += this.props.typingTime;
        return;
      }
      const characterTotal = this.props.text.length;
      if (this.charPos === characterTotal) {
        clearInterval(this.textWritingID);
        if (callback) callback();
        return;
      }
      this.setState({
        content: this.state.content + ' ' + this.props.text[this.charPos++],
      });
    }, this.props.typingTime);
  }

  render() {
    return (
      <p
        style={this.props.style}
        dangerouslySetInnerHTML={{ __html: this.state.content }}
      />
    );
  }
}
