import React from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import gradientImage from './images/gradient-image.png';
import phoneContact from './images/phone-contact.png';
import emailContact from './images/email-contact.png';
import 'animate.css';
import './styles.css';

function getImage(imageData, name) {
  const image = imageData.allFile.edges.find(edge => edge.node.name === name);
  return image.node.childImageSharp;
}

function animateCSS(element, animationName, callback) {
  const node = document.querySelector(element);
  node.classList.add('animated', animationName);

  function handleAnimationEnd() {
    node.classList.remove('animated', animationName);
    node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') callback();
  }

  node.addEventListener('animationend', handleAnimationEnd);
}

export default class Contact extends React.Component {
  state = {
    isOpen: false,
  };
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allFile(filter: { relativeDirectory: { eq: "contact/images" } }) {
              edges {
                node {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  name
                }
              }
            }
          }
        `}
        render={imgData => (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              zIndex: 100,
              height: this.state.isOpen ? '100%' : 'unset',
            }}
          >
            {!this.state.isOpen ? (
              <button
                aria-label="contact information button"
                className="reset-button cross-out"
                style={{
                  position: 'relative',
                  right: 20,
                  padding: '26px 12px',
                  textTransform: 'uppercase',
                  fontFamily: 'Sporting Grotesque',
                  fontSize: '12px',
                }}
                onClick={() => this.setState({ isOpen: true })}
              >
                <span>Contact</span>
              </button>
            ) : (
              <div
                id="contact-drawer"
                style={{
                  backgroundColor: '#020202',
                  width: '355px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  overflowY: 'auto',
                }}
                className="animated slideInUp"
              >
                <button
                  aria-label="close contact menu"
                  style={{
                    margin: '16px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 100,
                    height: 'auto',
                    width: '7%',
                  }}
                  className="reset-button"
                  onClick={() => {
                    animateCSS('#contact-drawer', 'slideOutDown', () =>
                      this.setState({ isOpen: false })
                    );
                  }}
                >
                  <Img
                    alt="close button"
                    fluid={getImage(imgData, 'custom-x-button').fluid}
                    fadeIn={false}
                  />
                </button>
                <img
                  alt="gradient"
                  src={gradientImage}
                  style={{
                    position: 'absolute',
                    top: 0,
                    marginTop: '9px',
                    width: '100%',
                  }}
                />
                <a
                  style={{ display: 'flex', justifyContent: 'center' }}
                  href="sms://+12395375411‬"
                >
                  <img alt="phone contact" src={phoneContact} />
                </a>
                <a
                  style={{ display: 'flex', justifyContent: 'center' }}
                  href="mailto:WELCOME@SANITARIUM.NYC"
                >
                  <img alt="email contact" src={emailContact} />
                </a>
                <div>
                  <p
                    style={{
                      position: 'absolute',
                      bottom: '15px',
                      left: '13px',
                      fontFamily: 'Akkurat-Mono',
                      fontSize: '12px',
                      color: '#FFFDFD',
                      textTransform: 'uppercase',
                    }}
                  >
                    If you have auto-fill
                    <br />
                    DAVID@SANITARIUM.NYC
                    <br />
                    (239) 537-5411
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      ></StaticQuery>
    );
  }
}
