export default [
  `<p style="font:0.5vw monospace !important; line-height:0.5vw;">`,
  ...`
<eol><br/>            *▄
<eol><br/>             ^█▄
<eol><br/>               ^▓,                                                                                                                                                                              ,█
<eol><br/>                 ▀▓                                                                                                                                                                            ▓▀
<eol><br/>                  ^▓▄                                                                                                                                                                        ▄▓\
<eol><br/>                    ▀▓▄                                     «▄                                                                                ╓▄                                           ╒▀▓¬
<eol><br/>                     ▀▄█,                             ,▌      ^▀█▄▄             ]                                             ]⌐         ,▄▓█▀¬                                          ,█^▓
<eol><br/>                      ╙▄ ▀Wç                         ,▓           ^▀▓▄▄         ▓                                              ▓     ,▄▓▓▀▀                                             #▀ ▓
<eol><br/>                       ╙▄   └▀▀▀▀▀▓▓▄▄╓              ▓▌            ▓▌ ▀▀▓▄,    ▐▌                                              ▓ ,▄█▓#▀,  ╔                                           ▄▀  á¬
<eol><br/>                        ▀µ     ╔▀' ' ¬'└▀           ▐▓∩           ▐▀▌    ▀▀▓▀▄ ▓▌                                             ▄█▀╓Æ▀     ╒▓                                         ╓▀   ╓▀
<eol><br/>                         ▓     └▌    ╥╖             ▓▓            ▌ ▀⌐      ▀▄,▀█▄                                         ▄▀▀▄▄▓⌐       ▌▐▌                                      ,█¬    ▌
<eol><br/>                          ▌     ^▓     ▀█▓▄,        ▓▐⌐     ▐▌   ▐⌐  ▀▄       ▓    └▀▀ª∞æ▄æxª█▀                ,▓▀     ,Æ▀',#▀ ▐▀▌      ▓  ▌               ,                     #\     ╟
<eol><br/>                          ▓       ▀▄    █▄▀▄└▀▀Wæ▄▄█" ▌     ▐▓   ▓     ▀▄     ▌▐▓▄          ╒▀            ╓▄  █▓\   ▄Æ▀   ▌    ▐ ▓     ▓¬  ▓       ,▄▓▀  ,#▀█▄    ,Æ▄          ▄▀      ]▌
<eol><br/>                          ▐▌  ▄▄   └█    ▀▓ ▀¼    %▄  ▐     ╟'▀▄█^▄▄     ▐⌐  ╟¬ ▌ ▓       ,,▓    ▌▀▀▀▀▀▀╘▓▀ ,▀ ▌ ▄#▓▄     ▓    ▓ ▐▌   ▐─   ╟   ,▄▀▀▓└ ,▄▀,    ¬▀▀,  ╙▄       ▄▀  ▄▄▄   ▐
<eol><br/>                           ▌ ▐∩ ^█,  ▀▄   ▌▀▄ ▀▄   ▀▌  █    ▓    ▐  '█   └▌  ▌  ▓  ▓▀└¬▓ ▐⌐ ╘   j▌      ▓  ▄▀  ▓▓▀▀ ╟  ▄▀'     ▌  ▓  ╓▌  ╓█.▄▀▀  ╓▀▄Æ▀,  ▄▀▀⌐        ╙▄    ,█' ▄▀   ▀  ▓
<eol><br/>                           ▓ ▓    '█   └▀▀  "█  █   ▀▓  ▀µ  ▀ç   ▐▄   ╙▄  ▓ ▐▌  └▌ ▀  j▌  ▌     ▐▌  ▓⌐  ▌ ▓    ▐    ▄▓▓▄ª▀▌   ▐¬  ▐⌐ ▌  ▄▀ ▐¬   Æ\▌    ▄▀  j▌   ,█▓   '█▄▄█"  ▓'     ▌ ▌
<eol><br/>                           ▓ ▌      ▀   ,     ▀▄ ▀⌐  ▓▓  └▄  ^█   ▀    "▌ '▌▓    ▀   ,▀   ╙▄    ▐  ▐▓▀  ▓  ▌    ▌▄▓▌'¬    ▓  ]▌    ▓▓  ▄─  ▓   ▓ ]▌   █    ▌   █¬ ▓     ▄ªª▄ ▄\      ▓ ▌
<eol><br/>                           ▌▐        ▓▓▀ '▀▄    ▀▄▀  ▐▓╟▌  ▀µ  ▀   ▀    └▌ ▐▌█,   ▓   ▓    ▀    ▓  '╓µ  ▐▄ ▐µ   .▓└'▓     ╫¬ ▌   ╓▀▓  ▓¬  j▌  ▓¬ ▓   ▌    ▓  ▄▀  ╓▀   ╓▀    ▓▌       ▐⌐▌
<eol><br/>                          ▐▄▌        ▐¬     ▓    ▓ ▓  ^▓ ▀⌐ ^▌  ▀   ▀    ▐⌐ ▀⌐^█   ▓   ▀    ▓   ▓   ▌╙▄  ▀  ▌   ▓   ▐▌   á─ ▌   ▓¬▐─ á¬   ▓  ▐─  ▌  ▓    ▓  █   ▄▀   ╓▀               ▓▌
<eol><br/>                          ▓▓        ╒▀▀µ     ▓   ▐ ▐⌐  ▀  ▀▄  ▓  ▓   ▀    ▓  ▀▄ ▓   ▓   ▓    ▓ ▐▌  j▌ ▐µ  ▀ ▓   ▌   ▐▌  ]▌ ▓   ▌ ╒▌ ╒▌   ]▌  ▌  ▓  ▐^   ▐¬ ▓  ,█¬   ╓▌                ▐▓
<eol><br/>                         ▓▓        ▄▀  ¬█µ    ▌   ▌ █   ▀▄ "▌  ▀µ ▌   ▀   ▐⌐  ▀▄ ▌   ▌   ▌    ▓▓   j▌  ▓   ▀▓   ▓   ▓   ▓ ▓   ▓ .▌  ▓    ▓  ╫  ]▌  ▓   ╓▀ ▓   ▌     ▓     ▄▀▀          ▓▌
<eol><br/>                        ╫▓       ╓▀       ▀▄,,▌   ▓  ╙▄  └▄ '▌  "▄└▌   ▀   ▌   ╙▄▐⌐  ^▌  ╟    j▓▓   ▓  ▐▌ ,▀▐⌐  ▐▄▄▀    ▌▓^  ▐▌ ▓  ]▌   ▓¬  ▌  ▓  j▌  ╓▀ ]▌   ▓     ▀, ,▄▀   ▀▄         ▓
<eol><br/>                       ▓▀       █¬   ,,,           ▌   ▓   ▓ "▌   ▓▓    ▀  ▓   ╓▀ ▌    █m▐    ▌  ▌  ▐⌐  ▌╓▀ ▐─   ▓▀µ   #▀    ▓ ▓   ▐⌐  Æⁿ   ▌ ▐▌  .▌ ▄▀  ▐⌐   ▓       '       "▓        ^▌
<eol><br/>                     ,▓¬      ▄▀ ,Æ▀¬   '^▀ª▄      ▓    ▓   ▓ ▐▄  ╟▀⌐   ▐⌐ ▓  ▄▀  ▌   ,▀ └▌  ╟   ▐   ▓ j▓▀  ▐─  █  ▓  .▌▀    ▓█,   ▐▌ █'    ▓  ▀▄ ▐▌█⌐   ▐⌐   ▌      ,▄ª▀▀▀▀╗,  ▀⌐
<eol><br/>                    Æ▀       á─▄▀└           '▀▄   ▐µ    ▌   ▀ ▓ ╒▌▐∩   ▌  ▓ █^   ▌  ╓▀   ╙▄ ▓    ▌  └▌█'   ▓ ╓▀   ▐  ▓  ▀▄  ╟ ▀⌐   "▓▀▀╙▄  ▐⌐  └▄ ▌ ▀▄  ▐▌   ▓    Æ▀         ▀¥,└▄
<eol><br/>                   "        ▓╓▀                 ▀▄ ▐▌    ▐⌐  ▓ ▐▄▌ ╟   ▓   ▓▀    ▐⌐ Æ\     └▄▓    ▌   ▓    ]▌█'    ▐⌐▓    ╙▄ ▐▌ ╙▄  ╫    ▀   ▀µ   ▓▓  "▓  ▓   ▓  ▄▀             '▀▄▓
<eol><br/>                           ▓▓¬                    ▀▄▌     ▌  ▌ ▐▓ ╓▌  j▌   ▀     ▌ ▓÷       ^▓∩   ▌ ▄▀     ▓▀      ╫▌      └▌ ▓   █ ▓     ▌   ^▓   ▓▌   ▀⌐"▌  ▐⌐▐¬                '▓▓
<eol><br/>                          ▐▓                       ╙▌     ▌ ▐▌ ▀  ╟   ▐¬        ▓ ▓           ▀  ▄\▓'              ▀         ▓ ▌   ▀▓⌐    ▌   ▓÷    ▀    └▄╙▄  ▓▌                   ▓▌
<eol><br/>                          ▓                               ▌.▀      ▓  ▓        ▓╓▌              ╓▓▀                           ▓,▓    ╘    ▌ ,█             ▀▓▄ ▐▌                    ▓
<eol><br/>                         ▐▌                              ▐▓▀        ▀▄▓      ╓▀▄▀               ¬                              ▀▄▀⌐      ▓╓█└               ^▓▓ ¬                    ▓µ
<eol><br/>                         ╟▌                             ]▓¬           └─    ▓▓▀                                                  ▀▓▄    ▓▀└                   ▀▓µ                    ▓
<eol><br/>                         ▐▌                             ▀                 ▄▓▀                                                      ▀▓▄ ▀                        ▀▓                  ^¬
<eol><br/>                          ▌                                            ▄█▀└                                                          └▀▓,                         ▀▄
<eol><br/>                          '                                          ª▀'                                                                └▀≈                         ▀▄
<eol><br/>                                                                                                                                                                      ▀
`
    .replace(/ /g, '\u00a0')
    .split('<eol>'),
  `<eol></p>`,
];
