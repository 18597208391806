export default `
<br/><eol>#include <errno.h>
<br/><eol>#include <fcntl.h>
<br/><eol>#include <stdio.h>
<br/><eol>#include <stdlib.h>
<br/><eol>#include <string.h>
<br/><eol>#include <unistd.h>
<br/><eol>#include <sys/epoll.h>
<br/><eol>#include <sys/types.h>
<br/><eol>#include <sys/socket.h>
<br/><eol>#include <netdb.h>
<br/><eol>
<br/><eol>static int
<br/><eol>create_and_bind (char *port)
<br/><eol>{
<br/><eol>  struct addrinfo hints;
<br/><eol>  struct addrinfo *result, *rp;
<br/><eol>  int s, sfd;
<br/><eol>
<br/><eol>  memset (&hints, 0, sizeof (struct addrinfo));
<br/><eol>  hints.ai_family = AF_UNSPEC;     /* Return IPv4 and IPv6 choices */
<br/><eol>  hints.ai_socktype = SOCK_STREAM; /* We want a TCP socket */
<br/><eol>  hints.ai_flags = AI_PASSIVE;     /* All interfaces */
<br/><eol>
<br/><eol>  s = getaddrinfo (NULL, port, &hints, &result);
<br/><eol>  if (s != 0)
<br/><eol>    {
<br/><eol>      fprintf (stderr, "getaddrinfo: %s\n", gai_strerror (s));
<br/><eol>      return -1;
<br/><eol>    }
<br/><eol>
<br/><eol>  for (rp = result; rp != NULL; rp = rp->ai_next)
<br/><eol>    {
<br/><eol>      sfd = socket (rp->ai_family, rp->ai_socktype, rp->ai_protocol);
<br/><eol>      if (sfd == -1)
<br/><eol>        continue;
<br/><eol>
<br/><eol>      s = bind (sfd, rp->ai_addr, rp->ai_addrlen);
<br/><eol>      if (s == 0)
<br/>        {
<br/>          /* We managed to bind successfully! */
<br/>          break;
<br/>        }
<br/>
<br/>      close (sfd);
<br/>    }
<br/>
<br/>  if (rp == NULL)
<br/>    {
<br/>      fprintf (stderr, "Could not bind\n");
<br/>      return -1;
<br/>    }
<br/>
<br/>  freeaddrinfo (result);
<br/>
<br/>  return sfd;
<br/>}
<br/>
<br/>static int
<br/>make_socket_non_blocking (int sfd)
<br/>{
<br/>  int flags, s;
<br/>
<br/><eol>  flags = fcntl (sfd, F_GETFL, 0);
<br/><eol>  if (flags == -1)
<br/><eol>    {
<br/><eol>      perror ("fcntl");
<br/><eol>      return -1;
<br/><eol>    }
<br/><eol>
<br/><eol>  flags |= O_NONBLOCK;
<br/><eol>  s = fcntl (sfd, F_SETFL, flags);
<br/><eol>  if (s == -1)
<br/><eol>    {
<br/><eol>      perror ("fcntl");
<br/><eol>      return -1;
<br/><eol>    }
<br/><eol>
<br/><eol>  return 0;
<br/><eol>}
<br/><eol>
<br/><eol>#define MAXEVENTS 64
<br/><eol>
<br/><eol>static const char reply[] =
<br/><eol>"HTTP/1.0 200 OK\r\n"
<br/><eol>"Content-type: text/html\r\n"
<br/><eol>"Connection: close\r\n"
<br/><eol>"Content-Length: 82\r\n"
<br/>"\r\n"
<br/>"<html>\n"
<br/>"<head>\n"
<br/>"<title>performance test</title>\n"
<br/>"</head>\n"
<br/>"<body>\n"
<br/>"test\n"
<br/>"</body>\n"
<br/>"</html>"
<br/>;
<br/>
<br/>int
<br/>main (int argc, char *argv[])
<br/>{
<br/>  int sfd, s;
<br/>  int efd;
<br/>  struct epoll_event event;
<br/>  struct epoll_event *events;
<br/>
<br/>  if (argc != 2)
<br/>    {
<br/>      fprintf (stderr, "Usage: %s [port]\n", argv[0]);
<br/>      exit (EXIT_FAILURE);
<br/>    }
<br/>
<br/>  sfd = create_and_bind (argv[1]);
<br/>  if (sfd == -1)
<br/>    abort ();
<br/>
<br/>  s = make_socket_non_blocking (sfd);
<br/>  if (s == -1)
<br/>    abort ();
<br/>
<br/>  s = listen (sfd, SOMAXCONN);
<br/>  if (s == -1)
<br/>    {
<br/>      perror ("listen");
<br/>      abort ();
<br/>    }
<br/><eol>
<br/><eol>  efd = epoll_create1 (0);
<br/><eol>  if (efd == -1)
<br/><eol>    {
<br/><eol>      perror ("epoll_create");
<br/><eol>      abort ();
<br/><eol>    }
<br/><eol>
<br/><eol>  event.data.fd = sfd;
<br/><eol>  event.events = EPOLLIN | EPOLLET;
<br/><eol>  s = epoll_ctl (efd, EPOLL_CTL_ADD, sfd, &event);
<br/><eol>  if (s == -1)
<br/><eol>    {
<br/><eol>      perror ("epoll_ctl");
<br/>      abort ();
<br/>    }
<br/>
<br/>  /* Buffer where events are returned */
<br/>  events = calloc (MAXEVENTS, sizeof event);
<br/>
<br/>  /* The event loop */
<br/>  while (1)
<br/>    {
<br/>      int n, i;
<br/>
<br/>      n = epoll_wait (efd, events, MAXEVENTS, -1);
<br/>      for (i = 0; i < n; i++)
<br/>	{
<br/>	  if ((events[i].events & EPOLLERR) ||
<br/>              (events[i].events & EPOLLHUP) ||
<br/>              (!(events[i].events & EPOLLIN)))
<br/>	    {
<br/>              /* An error has occured on this fd, or the socket is not
<br/>               * ready for reading (why were we notified then?) */
<br/>	      fprintf (stderr, "epoll error. events=%u\n", events[i].events);
<br/>	      close (events[i].data.fd);
<br/>	      continue;
<br/>	    }
<br/>
<br/>	  else if (sfd == events[i].data.fd)
<br/>	    {
<br/>              /* We have a notification on the listening socket, which
<br/>               * means one or more incoming connections. */
<br/>              while (1)
<br/>                {
<br/>                  struct sockaddr in_addr;
<br/>                  socklen_t in_len;
<br/><eol>                  int infd;
<br/><eol>#if 0
<br/><eol>                  char hbuf[NI_MAXHOST], sbuf[NI_MAXSERV];
<br/><eol>#endif
<br/><eol>
<br/><eol>                  in_len = sizeof in_addr;
<br/><eol>                  infd = accept (sfd, &in_addr, &in_len);
<br/><eol>                  if (infd == -1)
<br/><eol>                    {
<br/><eol>printf("errno=%d, EAGAIN=%d, EWOULDBLOCK=%d\n", errno, EAGAIN, EWOULDBLOCK);
<br/><eol>                      if ((errno == EAGAIN) ||
<br/><eol>                          (errno == EWOULDBLOCK))
<br/><eol>                        {
<br/><eol>                          /* We have processed all incoming
<br/><eol>                           * connections. */
<br/><eol>                          printf ("processed all incoming connections.\n");
<br/><eol>                          break;
<br/><eol>                        }
<br/><eol>                      else
<br/><eol>                        {
<br/><eol>                          perror ("accept");
<br/><eol>                          break;
<br/><eol>                        }
<br/><eol>                    }
<br/><eol>
<br/><eol>#if 0
<br/><eol>                  s = getnameinfo (&in_addr, in_len,
<br/>                                   hbuf, sizeof hbuf,
<br/>                                   sbuf, sizeof sbuf,
<br/>                                   NI_NUMERICHOST | NI_NUMERICSERV);
<br/>                  if (s == 0)
<br/>                    {
<br/>                      printf("Accepted connection on descriptor %d "
<br/>                             "(host=%s, port=%s)\n", infd, hbuf, sbuf);
<br/>                    }
<br/>#endif
<br/>
<br/>                  /* Make the incoming socket non-blocking and add it to the
<br/>                   * list of fds to monitor. */
<br/>                  s = make_socket_non_blocking (infd);
<br/>                  if (s == -1)
<br/>                    abort ();
<br/>
<br/>                  event.data.fd = infd;
<br/>                  event.events = EPOLLIN | EPOLLET;
<br/>printf("set events %u, infd=%d\n", event.events, infd);
<br/>                  s = epoll_ctl (efd, EPOLL_CTL_ADD, infd, &event);
<br/>                  if (s == -1)
<br/>                    {
<br/>                      perror ("epoll_ctl");
<br/>                      abort ();
<br/>                    }
<br/>                }
<br/>/*              continue; */
<br/>            }
<br/>          else
<br/>            {
<br/>              /* We have data on the fd waiting to be read. Read and
<br/>               * display it. We must read whatever data is available
<br/>               * completely, as we are running in edge-triggered mode
<br/>               * and won't get a notification again for the same
<br/>               * data. */
<br/>              int done = 0;
<br/>
<br/>              while (1)
<br/>                {
<br/>                  ssize_t count;
<br/>                  char buf[512];
<br/>
<br/>                  count = read (events[i].data.fd, buf, sizeof buf);
<br/>                  if (count == -1)
<br/>                    {
<br/>                      /* If errno == EAGAIN, that means we have read all
<br/>                       * data. So go back to the main loop. */
<br/>                      if (errno != EAGAIN)
<br/>                        {
<br/>                          perror ("read");
<br/>                          done = 1;
<br/>                        }
<br/>                      break;
<br/>                    }
<br/><eol>                  else if (count == 0)
<br/><eol>                    {
<br/><eol>                      /* End of file. The remote has closed the
<br/><eol>                       * connection. */
<br/><eol>                      done = 1;
<br/><eol>                      break;
<br/><eol>                    }
<br/><eol>
<br/><eol>                  /* Write the reply to connection */
<br/><eol>                  s = write (events[i].data.fd, reply, sizeof(reply));
<br/><eol>                  if (s == -1)
<br/><eol>                    {
<br/><eol>                      perror ("write");
<br/><eol>                      abort ();
<br/><eol>                    }
<br/><eol>                }
<br/><eol>
<br/><eol>              if (done)
<br/><eol>                {
<br/><eol>                  printf ("Closed connection on descriptor %d\n",
<br/><eol>                          events[i].data.fd);
<br/><eol>
<br/><eol>                  /* Closing the descriptor will make epoll remove it
<br/><eol>                   * from the set of descriptors which are monitored. */
<br/><eol>                  close (events[i].data.fd);
<br/><eol>                }
<br/><eol>            }
<br/><eol>        }
<br/><eol>    }
<br/><eol>
<br/><eol>  free (events);
<br/><eol>
<br/><eol>  close (sfd);
<br/><eol>
<br/><eol>  return EXIT_SUCCESS;
<br/><eol>}
<br/>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>
.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>.<eol>
`
  .replace(/ /g, '\u00a0')
  .split('<eol>');
