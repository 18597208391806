import React from 'react';
import { MenuProps } from './index';
import Toggle from '../toggle';
import CloseButton from '../close-button';
import Typed from 'typed.js';

export default class MobileBottomMenu extends React.Component<MenuProps> {
  state = {
    isFiltersOpen: false,
  };
  typed: Typed | undefined;

  clickFilters = () => {
    // open filters page
    this.setState({ isFiltersOpen: true });
  };

  closeFilters = () => {
    this.setState({ isFiltersOpen: false });
  };

  componentDidUpdate(prevProps: MenuProps) {
    if (this.props.about !== prevProps.about) {
      setTimeout(() => {
        if (typeof window !== 'undefined') {
          const typingElement = document.querySelector('#typed');
          const stringsElement = document.querySelector('#typed-strings');

          if (typingElement && stringsElement) {
            if (this.typed) this.typed.destroy();
            this.typed = new Typed('#typed', {
              stringsElement: '#typed-strings',
              showCursor: false,
            });
          }
        }
      }, 50);
    }
  }

  render() {
    const props = this.props;
    return (
      <div>
        {this.state.isFiltersOpen && (
          <Filters {...props} onClickClose={this.closeFilters} />
        )}
        <button
          aria-label="configure filters"
          className="reset-button"
          style={{
            width: '100%',
            border: '1px solid #0e0e0e',
            boxSizing: 'border-box',
            fontFamily: 'Akkurat-Mono',
            fontSize: '12px',
            textTransform: 'uppercase',
            color: '#000000',
            cursor: 'pointer',
          }}
          onClick={this.clickFilters}
        >
          <p style={{ textAlign: 'center' }}>FILTERS</p>
        </button>
        <div
          style={{
            height: '20vh',
            fontFamily: 'Akkurat-Mono',
            boxSizing: 'border-box',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '18px',
            textTransform: 'uppercase',
            color: '#000000',
            padding: '12px',
            border: '1px solid #0e0e0e',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: props.about || props.menuDescription,
            }}
            style={{ textAlign: 'center' }}
          ></p>
        </div>
      </div>
    );
  }
}

interface FiltersProps extends MenuProps {
  onClickClose: () => void;
}

const Filters = (props: FiltersProps) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 50000,
      }}
    >
      <CloseButton onClick={props.onClickClose} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {props.filterButtons.map((btn: any, i: number) => (
          <div
            key={i}
            style={{
              display: 'grid',
              gridTemplateColumns: '20% 30% 30% 20%',
            }}
          >
            <div></div>
            <p
              style={{
                fontFamily: 'Sporting Grotesque',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '20px',
                textTransform: 'uppercase',
                color: '#000000',
              }}
            >
              {btn.name}
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Toggle checked={btn.selected} onChange={btn.onClickButton} />
            </div>
            <div></div>
          </div>
        ))}
      </div>
    </div>
  );
};
