import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import '../fonts/stylesheet.css';
import './index.css';
import Menu from '../components/bottom-menu';
import Logo from '../components/logos';
import Contact from '../components/contact';
import PageContent from '../components/image-area';
import LoadingScreen from '../components/loading-screen';
import { graphql } from 'gatsby';

export class IndexPageTemplate extends React.Component {
  state = {
    isGameOpen: false,
    filterList: [],
    about: '',
  };
  render() {
    const filterOptions = [
      'art',
      'web',
      'brand',
      'product',
      'photo',
      'video',
      'stationary',
    ];
    const menuProps = {
      gameInstructions: `
      <style>
      #controller-area table, #controller-area th, #controller-area td {
        border: 1px solid black;
      }
      #controller-area th, #controller-area td {
        padding: 6px;
      }
      </style>
      <h1>INTRODUCTION</h1>
      <h2>GOOD EEEEVENING</h2>
      <p>
        Step into the shadows of the deadliest dwelling on earth. You've arrived at Castlevania, and you're here on business:
      </p>
      <p>
        To destroy forever the Curse of the Evil Count.
      </p>
      <p>
        Unfortunately, everybod's home this evening. Bats, ghosts, aevery kind of creature you can imageine. You'll find 'em all over the place, if they don't find you first.
      </p>
      <p>
        Because you've got to get through six monstrous floors before you even meet up with the Master of the House. Your Magic Whip will help, and you'll probably find a weapon or two along the way.
      </p>
      <p>
        But once you make it to the tower, you can count on a Duel to the Death. The Count has waited 100 years for a rematch. He's ready. Are you?
      </p>
      <h1>HOW TO PLAY</h1>
      <p>
        The object is to move through the passages and stairways of the castle to reach the tower, dodging or destroying every creature in your path. Use the control, as directed to activate your Magic Whip and the other weapons you'll find along the way. And watch for secret doors! To begin, hit the START button, and your nightmare begins.
      </p>
      <table id="controller-area">
      <tr><th>Controller Button</th><th>Keyboard Key</th><tr>
      <tr><td>BUTTON_A</td> <td>X</td></tr>
      <tr><td>BUTTON_B</td> <td>Y (Central European keyboard)</td></tr>
      <tr><td>BUTTON_B</td> <td>Z</td></tr>
      <tr><td>BUTTON_SELECT</td> <td>Right Ctrl</td></tr>
      <tr><td>BUTTON_START</td> <td>Enter</td></tr>
      <tr><td>BUTTON_UP</td> <td>Up</td></tr>
      <tr><td>BUTTON_DOWN</td> <td>Down</td></tr>
      <tr><td>BUTTON_LEFT</td> <td>Left</td></tr>
      <tr><td>BUTTON_RIGHT</td> <td>Right</td></tr>
      <tr><td>BUTTON_A</td> <td>Num-7</td></tr>
      <tr><td>BUTTON_B</td> <td>Num-9</td></tr>
      <tr><td>BUTTON_SELECT</td> <td>Num-3</td></tr>
      <tr><td>BUTTON_START</td> <td>Num-1</td></tr>
      <tr><td>BUTTON_UP</td> <td>Num-8</td></tr>
      <tr><td>BUTTON_DOWN</td> <td>Num-2</td></tr>
      <tr><td>BUTTON_LEFT</td> <td>Num-4</td></tr>
      <tr><td>BUTTON_RIGHT</td> <td>Num-6</td></tr>
      </table>
      <br/>
      <a href="https://www.nintendo.co.jp/clv/manuals/en/pdf/CLV-P-NABQE.pdf">FULL INSTRUCTIONS</a>
    `,
      about: this.state.about,
      menuDescription: `
      A talented few - accomplished in the consumer goods space. Services include art direction - branding - web development - production faciltation & consulting.
      `,
      filterButtons: filterOptions.map(option => {
        const isCurrentlySelected = this.state.filterList.includes(option);
        return {
          name: option,
          selected: isCurrentlySelected,
          onClickButton: () => {
            const filterList = this.state.filterList;
            if (isCurrentlySelected) {
              // remove
              this.setState({
                filterList: filterList.filter(i => !(i === option)),
              });
            } else {
              // add
              filterList.push(option);
              this.setState({
                filterList,
              });
            }
          },
        };
      }),
    };
    const imageAreaProps = this.props.images.map(img => {
      try {
        const about = img.about.code;
        return {
          ...img,
          x: img.xPosition,
          y: img.yPosition,
          fluid: img.image.childImageSharp.fluid,
          onMouseEnter: () => this.setState({ about }),
          onMouseLeave: () => this.setState({ about: '' }),
        };
      } catch (error) {
        console.error(error);
        return img;
      }
    });

    const filterList =
      this.state.filterList.length === 0
        ? filterOptions
        : this.state.filterList;
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <iframe src="/test.html" /> */}
        <Contact />
        <Logo />
        <PageContent
          imageAreaProps={imageAreaProps.map(i => {
            return {
              ...i,
              show: filterList.includes(i.tag),
            };
          })}
          menuHeight={this.state.isGameOpen ? 458 : 175}
        ></PageContent>
        <div id="bottom-content">
          <Menu {...menuProps} />
        </div>
      </div>
    );
  }
}

const IndexPage = ({ data }) => {
  const allImageRelativePaths =
    data.allMarkdownRemark.nodes[0].frontmatter.images;
  const images = data.markdownRemark.frontmatter.images.map(img => {
    if (!img.image || !img.image.childImageSharp) {
      // if a GIF meaning file not loaded
      const foundImage = allImageRelativePaths.find(
        gif =>
          gif.xPosition === img.xPosition && gif.yPosition === img.yPosition
      );
      return {
        ...img,
        image: {
          childImageSharp: {
            fluid: {
              src: foundImage.image.publicURL,
            },
          },
        },
      };
    } else {
      return img;
    }
  });
  return (
    <Layout>
      <LoadingScreen />
      <IndexPageTemplate images={images} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        images {
          xPosition
          image {
            childImageSharp {
              fluid(maxWidth: 2080, quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
                presentationHeight
              }
            }
          }
          alt
          yPosition
          tag
          height
          width
          about {
            code
          }
        }
      }
    }
    allMarkdownRemark {
      nodes {
        frontmatter {
          images {
            image {
              publicURL
            }
            xPosition
            yPosition
          }
        }
      }
    }
  }
`;
