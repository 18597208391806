import React from 'react';
import './styles.css';
import { MenuProps } from './index';
import Typed from 'typed.js';

export default class BottomMenu extends React.PureComponent<MenuProps> {
  state = {
    isPlayingGame: false,
  };
  typed: Typed | undefined;

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') return;
    try {
      const gameElement = document.getElementById('game-area')!;
      gameElement.innerHTML = `
      <iframe
        id="game"
        src="https://determined-franklin-e527e4.netlify.app/run/castlevania"
        style="min-width: ${this.state.isPlayingGame ? 400 : 200}px;"
        title="8-bit Game"
        width="${this.state.isPlayingGame ? 400 : 200}"
        height="${this.state.isPlayingGame ? 400 : 175}"
        frameBorder="no"
        allowFullScreen={true}
        scrolling="no"
      ></iframe>
      `;
    } catch (error) {
      console.error(error);
    }
  }

  onClickPlayGame = () => {
    const floatingSkull = document.getElementById('floating-skull')!;
    floatingSkull.style.bottom = '400px';
    const game = document.getElementById('game')!;
    game.style.height = '400px';
    game.style.width = '400px';

    this.setState({ isPlayingGame: true });
  };
  onClickExitGame = () => {
    const floatingSkull = document.getElementById('floating-skull')!;
    floatingSkull.style.bottom = '175px';
    const game = document.getElementById('game')!;
    game.style.height = '175px';
    game.style.width = '175px';

    this.setState({ isPlayingGame: false });
  };

  componentDidUpdate(prevProps: MenuProps) {
    if (this.props.about !== prevProps.about) {
      setTimeout(() => {
        if (typeof window !== 'undefined') {
          const typingElement = document.querySelector('#typed');
          const stringsElement = document.querySelector('#typed-strings');

          if (typingElement && stringsElement) {
            if (this.typed) this.typed.destroy();
            this.typed = new Typed('#typed', {
              stringsElement: '#typed-strings',
              showCursor: false,
            });
          }
        }
      }, 50);
    }
  }

  render() {
    const props = this.props;
    const FilterMenu = (
      <>
        <div
          style={{
            height: '100%',
            maxWidth: '361px',
            fontFamily: 'Akkurat-Mono',
            boxSizing: 'border-box',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            textTransform: 'uppercase',
            color: '#000000',
            padding: '12px',
            border: '0.5px solid #0e0e0e',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p>{props.menuDescription}</p>
        </div>
        <div
          style={{
            position: 'relative',
            width: '25px',
            height: '100%',
            border: '1px solid #0e0e0e',
            boxSizing: 'border-box',
            fontFamily: 'Akkurat-Mono',
            fontSize: '12px',
            lineHeight: '12px',
            textTransform: 'uppercase',
            color: '#000000',
          }}
        >
          <div style={{ whiteSpace: 'nowrap' }} className="rotate-90">
            FILTERS
          </div>
        </div>
        {props.filterButtons.map((btn: any, i: number) => (
          <button
            key={i}
            aria-label={`filter by ${btn.name}`}
            className={`btn ${btn.selected ? 'btn-highlighted' : ''}`}
            onClick={btn.onClickButton}
          >
            <div style={{ whiteSpace: 'nowrap' }} className="rotate-90">
              {btn.name}
            </div>
          </button>
        ))}
        <div
          style={{
            flex: '1',
            minWidth: '340px',
            border: '0.5px solid #0E0E0E',
            boxSizing: 'border-box',
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: 'Akkurat-Mono',
            fontWeight: 500,
            fontSize: '12px',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
          dangerouslySetInnerHTML={{ __html: props.about }}
        ></div>
        <button
          aria-label="play game button"
          style={{ marginLeft: 'auto' }}
          className={`btn`}
          onClick={this.onClickPlayGame}
        >
          <div style={{ whiteSpace: 'nowrap' }} className="rotate-90">
            Play
          </div>
        </button>
      </>
    );

    const GameMenu = (
      <>
        <div
          style={{
            width: '400px',
            padding: '12px',
            height: '100%',
            fontFamily: 'Akkurat-Mono',
            boxSizing: 'border-box',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '18px',
            textTransform: 'uppercase',
            textAlign: 'center',
            color: '#000000',
            border: '0.5px solid #0e0e0e',
            overflowY: 'auto',
          }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: props.gameInstructions,
            }}
          />
        </div>
        <button
          aria-label="exit game button"
          style={{
            border: '0.5px solid #0e0e0e',
            boxSizing: 'border-box',
            fontFamily: 'Sporting Grotesque',
            fontWeight: 'bold',
            fontSize: '18px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            backgroundColor: 'unset',
            flex: 1,
            height: '100%',
          }}
          onClick={this.onClickExitGame}
        >
          Exit Game
        </button>
      </>
    );
    return (
      <div
        id="menu"
        style={{
          display: 'flex',
          border: '0.5px solid #0e0e0e',
          alignItems: 'center',
          backgroundColor: '#fffefe',
          zIndex: 50,
          height: `${this.state.isPlayingGame ? '400' : '175'}px`,
        }}
      >
        {this.state.isPlayingGame ? GameMenu : FilterMenu}
        <div
          id="game-area"
          style={{
            border: '1px solid #0E0E0E',
            boxSizing: 'border-box',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <div
            id="game"
            style={{
              width: `${this.state.isPlayingGame ? 400 : 200}px`,
              height: `${this.state.isPlayingGame ? 400 : 175}px`,
              backgroundColor: 'black',
            }}
          ></div>
        </div>
      </div>
    );
  }
}
